<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="货主名称"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.name"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item label="货主简称"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.shortname"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type="primary" class="buttons" @click="goAdd"
              >新增货主</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableList" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货主简称"
            prop="shortname"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货主"
            prop="name"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货主电话"
            prop="telephone"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="地址">
            <template v-slot="scope">
              {{
                scope.row.addrProvince +
                "/" +
                scope.row.addrCity +
                "/" +
                scope.row.addrCounty
              }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="详细地址"
            prop="addrDetail"
          ></el-table-column>
          <el-table-column label="审核状态">
            <template v-slot="scope">
              <el-tag size="small" :type="enumStatus[scope.row.status].type">{{
                enumStatus[scope.row.status].label
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="goEdit(scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleDelete(scope.row.id)"
                style="color: rgb(254, 90, 36)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
export default {
  data() {
    return {
      enumStatus: {
        0: { label: "待审核", type: "" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      conditions: {
        page: 1,
        limit: 10,
        name: "",
        shortname: "",
      },
      total: 0,
      tableList: [],
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    Pagination,
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList(num) {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        this.conditions
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    async changeStatus(id, status) {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/update",
        "post",
        {
          id,
          status,
        }
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.$message.success("操作成功");
      this.getList();
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该货主, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/EnterpriseInfo/delete",
            "post",
            {
              ids: [id],
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },
    goAdd() {
      this.$router.push({ path: "/baseInfo/addOwner" });
    },
    goEdit(row) {
      this.$router.push({ path: "/baseInfo/addOwner", query: { id: row.id } });
    },
  },
};
</script>
<style lang="scss" scoped></style>
